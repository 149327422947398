import React, { Component } from 'react'
import logo from './logo.svg';
import './App.css';
import $ from 'jquery';
import moment from 'moment-timezone';
import { Lightbox } from "react-modal-image";
import { db, storage, auth } from './firebase'
import { Link } from 'react-router-dom';
import Form from './Components/Form'
class History extends Component {
  constructor(props) {
    super(props)
    this.state = {
      history: [],
      url: '',
      open: false,
      alt: '',
      kpiType: "",
      selectedDateRange: [moment().subtract(7, 'days'), moment()],
      updateComponent: true
    }
  }

  onChangeDates = (dateRange) => {
    this.setState({ selectedDateRange: dateRange });
    // this.forceUpdate()
  }
  onChangeKpi = (val) => {
    this.setState({ kpiType: val })
    // this.forceUpdate()
  }


  getRecord() {
    let that = this
    try {
      let collection = db.collection("CameraLog")
      if (this.state.kpiType.length > 0) {
        collection
          .where("KPI", "==", this.state.kpiType)
          .where('time', '>=', this.state.selectedDateRange[0].toDate())
          .where('dismissed', '==', false)
          .where('time', '<=', this.state.selectedDateRange[1].toDate())
          .orderBy("time", "desc")
          .onSnapshot(function (snapshot) {
            let docs = []
            snapshot.docs.forEach(x => {
              if (x.exists)
                docs.push({ id: x.id, ...x.data() })
            })
            that.setState({ history: docs })

          });
      }
      else {
        collection
          .where('time', '>=', this.state.selectedDateRange[0].toDate())
          .where('dismissed', '==', false)
          .where('time', '<=', this.state.selectedDateRange[1].toDate())
          .orderBy("time", "desc")
          .onSnapshot(function (snapshot) {
            let docs = []
            snapshot.docs.forEach(x => {
              if (x.exists)
                docs.push({ id: x.id, ...x.data() })
            })
            that.setState({ history: docs })

          });
      }
    } catch (error) {
      alert(error)
    }
  }

  componentDidMount() {

    this.getRecord()

  }

  async view(alert) {
    let url = await storage.refFromURL(alert.imageUrl).getDownloadURL()
    this.setState({
      open: true,
      alt: `${alert.KPI} reported at ${alert.time.toDate()}`,
      url: alert.imageUrl
    })

  }
  dismiss(id) {
    db.collection("CameraLog").doc(id).update({ 'dismissed': true })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.kpiType !== this.state.kpiType) {
      this.getRecord()
    }
    if (prevState.selectedDateRange[0].diff(this.state.selectedDateRange[0], 'minutes') !== 0 || prevState.selectedDateRange[1].diff(this.state.selectedDateRange[1], 'minutes') !== 0) {
      this.getRecord()
    }

  }
  render() {
    return (
      <div>
        {
          this.state.open && (
            <Lightbox
              medium={this.state.url}
              large={this.state.url}
              hideDownload={true}
              alt={this.state.alt}
              onClose={() => this.setState({ open: false })}
            />
          )
        }

        <div className="col-12 p-0 row m-0">
          <div id="MenuBox" className="MenuBox mr-4 AlertBos MenuBoxAppear">
            <div className="col-12">
              <ul className="dashboardMenu">
                <li className="Active">
                  <Link to="/" className="Dashboard"><b>Dashboard</b></Link>
                </li>
                <li>
                  <Link to="/history" className="History"><b>History</b></Link>
                </li>
                {/*<li>
                  <a href="#" className="Alert"><b>Alert</b></a>
                </li>
                <li>
                  <a href="#" className="VideoFeed"><b>Video&nbsp;Feed</b></a>
                </li>
                <li>
                  <a href="#" className="PredictorHome"><b>Predictor&nbsp;Home</b></a>
                </li>
                <li>
                  <a href="#" className="Report"><b>Reports</b></a>
                </li>
                <li>
                  <a href="#" className="Settings"><b>Settings</b></a>
                </li>*/}
                <li onClick={() => auth.signOut()}>
                  <a href="#" className="Logout"><b>Log&nbsp;Out</b></a>
                </li>
              </ul>
            </div>
          </div>



          <div className="col-md-10 AlertBos AlertBtn">
            <p className="text-right">
              <img src="vidan-logo-01.png" style={{ width: '160px', height: '40px' }} />
            </p>
            <div className="col-12">
              <h2 className="col-12 mb-3 tableTitle">Alerts History</h2>
              <Form selectedDateRange={this.state.selectedDateRange} onChangeKpi={this.onChangeKpi}
                selectedKpi={this.state.kpiType}
                onChangeDates={this.onChangeDates} />
            </div>

            <div className="col-12 p-0 m-0 HistoryAlertScroll">
              <div className="card-container">
                {this.state.history.map(alert => (
                  <div className="col-12 text-center mt-2 alertRows">
                    <h6 style={{ fontWeight: "bold" }}>{alert.KPI}</h6>
                    <p style={{ fontSize: 15 }}>Date: {moment(alert.time.toDate()).format("DD/MM/YYYY")}</p>

                    <p style={{ fontSize: 15 }}>time: {moment(alert.time.toDate()).format("HH:mm")}</p>
                    <div className="col-12 p-0 m-0 row justify-content-center">
                      <a onClick={() => this.dismiss(alert.id)} className="btn bg-danger text-white m-1">DISMISS</a>
                      <a onClick={() => this.view(alert)} className="btn bg-primary text-white m-1">VIEW</a>
                    </div>

                  </div>))}
              </div>


            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default History
