import React from "react";
import Login from "./login";
import Login1 from "./login1"
import App from "./App";
import { auth } from "./firebase"
import History from './history'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

class Application extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = {
        isLoggedIn: null,
        isLoading:true
        };
    }
  
    componentDidMount() {
      auth.onAuthStateChanged(user => {
        if (user) { this.setState({ isLoggedIn: true,})} 
        else { this.setState({ isLoggedIn: false,})}
        this.setState({isLoading:false})
      })
    }
  
    render() {
      return (
        <BrowserRouter>
          <Switch>
            <Route path='/login' render={() => ( !this.state.isLoggedIn || this.state.isLoading ? <Login1 /> : <Redirect to='/' /> )}/>
            <Route exact path='/' render={() => (
              this.state.isLoggedIn || this.state.isLoading ? <App /> : <Redirect to='/login' />
            )} />
            <Route path='/history' exact  render={() => (
              this.state.isLoggedIn || this.state.isLoading ? <History /> : <Redirect to='/login' />
            )} />
            {/* <Route path='/history' component={History}/> */}
          </Switch>
        </BrowserRouter>
      );
    } 
  }
  
  export default Application;