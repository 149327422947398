import React, { useState, Component } from 'react';
import { Form, Input, Button, Radio } from 'antd';
import { DatePicker, Select } from 'antd';

const { RangePicker } = DatePicker;



const formItemLayout = {
    wrapperCol: { span: 30 },
}
// const buttonItemLayout =
// formLayout === 'horizontal'
//     ? {
//         wrapperCol: { span: 14, offset: 4 },
//     }
//     : null;
class FormLayoutDemo extends Component {
    render() {



        return (
            <>
                <Form
                    {...formItemLayout}
                    layout={'inline'}

                    initialValues={{ layout: 'inline' }}
                >
                    <Form.Item label="KPI">
                        <Select style={{ width: 200 }} value={this.props.selectedKpi} onChange={(val) => { this.props.onChangeKpi(val) }}>
                            <Select.Option value="">All</Select.Option>
                            <Select.Option value="Gun Detection">Gun Detection</Select.Option>
                            <Select.Option value="Fire Detection">Fire Detection</Select.Option>
                            <Select.Option value="CellPhone Detection">CellPhone Detection</Select.Option>
                            <Select.Option value="Rifle Detection">Rifle Detection</Select.Option>
                            <Select.Option value="Intruder Detection">Intruder Detection</Select.Option>
                            <Select.Option value="Person Limit Violation">Person Limit Violation
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Select Date">
                        <RangePicker value={this.props.selectedDateRange} onChange={(dateRange) => { this.props.onChangeDates(dateRange) }} />
                    </Form.Item>

                </Form>
            </>
        );
    }
};

export default FormLayoutDemo