import React from 'react';
//import logo from './logo.svg';
import './App.css';
import $ from 'jquery';
import moment from 'moment-timezone';
import { db, storage, auth } from './firebase'
import { Lightbox } from "react-modal-image";
import { Link } from 'react-router-dom';


export default class Vidan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alerts: [],
      url: '',
      open: false,
      alt: '',
      personLimitViolation: 0,
      mobileUsage: 0

    }
  }

  async componentDidMount() {
    $(document).ready(function () {
      // $('.Count').each(function () {
      //     $(this).prop('Counter',0).animate({
      //         Counter: $(this).text()
      //     }, {
      //         duration: 3000,
      //         easing: 'swing',
      //         step: function (now) {
      //             $(this).text(Math.ceil(now));
      //         }
      //     });
      // });

      $("#MenuBox").mouseenter(function () {
        // $('#MenuBox').addClass('MenuBoxAppear');
        //$('#MenuBox b').delay(5000).show();
      });
      $("#MenuBox").mouseleave(function () {
        // $('#MenuBox').removeClass('MenuBoxAppear');
        //$('#MenuBox b').delay(5000).hide();
      });

    });
    let start = moment().startOf('day').toDate();
    //let end = moment().endOf('day').toDate();

    let that = this
    try {
      db.collection("CameraLog")
        .where('time', '>', start)
        .where('dismissed', '==', false)
        .orderBy("time", "desc")
        .onSnapshot(function (snapshot) {
          let docs = []
          snapshot.docs.forEach(x => {
            if (x.exists)
              docs.push({ id: x.id, ...x.data() })
          })
          let personLimitViolation = docs.filter(x => x.KPI == "Person Limit Violation");
          let mobileUsage = docs.filter(x => x.KPI == "CellPhone Detection");
          that.setState({ alerts: docs, personLimitViolation: personLimitViolation.length, mobileUsage: mobileUsage.length })


        });
    } catch (error) {
      alert(error)
    }

  }
  dismiss(id) {
    db.collection("CameraLog").doc(id).update({ 'dismissed': true })
  }

  async view(alert) {
    // let url = await storage.refFromURL(alert.imageUrl).getDownloadURL()
    this.setState({
      open: true,
      alt: `${alert.KPI} reported at ${alert.time.toDate()}`,
      url: alert.imageUrl
    })
  }
  render() {
    return (
      <div>
        {
          this.state.open && (
            <Lightbox
              medium={this.state.url}
              large={this.state.url}
              hideDownload={true}
              alt={this.state.alt}
              onClose={() => this.setState({ open: false })}
            />
          )
        }
        {/*[if lt IE 7]>      <html class="no-js lt-ie9 lt-ie8 lt-ie7" lang="en-GB"> <![endif]*/}
        {/*[if IE 7]>         <html class="no-js lt-ie9 lt-ie8 ie-7" lang="en-GB"> <![endif]*/}
        {/*[if IE 8]>         <html class="no-js lt-ie9 ie-8" lang="en-GB"> <![endif]*/}
        {/*[if gt IE 8]><!*/}  {/*<![endif]*/}
        <title>Video Analytics</title>
        <link rel="shortcut icon" href="assets/icon.ico" />
        <link href="https://fonts.googleapis.com/css?family=Muli:300,400,500,700,800&display=swap" rel="stylesheet" />
        <link href="cssjs/bootstrap.min.css" rel="stylesheet" />
        <link href="cssjs/support.css" rel="stylesheet" />
        <div className="col-12 p-0 row m-0">
          <div id="MenuBox" className="MenuBox mr-4 AlertBos MenuBoxAppear">
            <div className="col-12">
              <ul className="dashboardMenu">
                <li className="Active">
                  <Link to="/" className="Dashboard"><b>Dashboard</b></Link>
                </li>
                {/* 
                 </li>
                <li>
                  <a href="#" className="Alert"><b>Alert</b></a>
                </li>
                <li>
                  <a href="#" className="VideoFeed"><b>Video&nbsp;Feed</b></a>
                </li>
                <li>
                  <a href="#" className="PredictorHome"><b>Predictor&nbsp;Home</b></a>
                </li>
                <li>
                  <a href="#" className="Report"><b>Reports</b></a>
                </li>
                <li>
                  <a href="#" className="Settings"><b>Settings</b></a>
                </li>*/}
                <li>
                  <Link to="/history" className="History"><b>History</b></Link>
                </li>
                <li onClick={() => auth.signOut()}>
                  <a href="#" className="Logout"><b>Log&nbsp;Out</b></a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col row">
            <div className="col-md-12 row UsageTable align-content-start">
              <div className="col-12">
                <h2 className="col-12 mb-3 tableTitle">OBJECT COUNT</h2>
              </div>
              <div className="col row m-0 text-center">
                <a href="#" className="WorkerIconClasss">
                  <h6 className="col-12 p-0 m-0">WORKER IN FACTORY</h6>
                  <h1 className="col-12 p-0 m-0 Count WorkerSink">0</h1>
                </a>
              </div>
              <div className="col row m-0 text-center">
                <a href="#" className="UserIconClasss">
                  <h6 className="col-12 p-0 m-0">Person Limit Violation</h6>
                  <h1 className="col-12 p-0 m-0 Count IDELSink">{this.state.personLimitViolation}</h1>
                </a>
              </div>
              <div className="col row m-0 text-center">
                <a href="#" className="MobileUsageClasss">
                  <h6 className="col-12 p-0 m-0">MOBILE USAGE</h6>
                  <h1 className="col-12 p-0 m-0 Count MobileSink">{this.state.mobileUsage}</h1>
                </a>
              </div>
              <div className="col row m-0 text-center">
                <a href="#" className="PackageClasss">
                  <h6 className="col-12 p-0 m-0">PACKAGE</h6>
                  <h1 className="col-12 p-0 m-0 Count PackageSink">0</h1>
                </a>
              </div>
              <div className="col row m-0 text-center">
                <a href="#" className="SpittingClass">
                  <h6 className="col-12 p-0 m-0">SPILLING</h6>
                  <h1 className="col-12 p-0 m-0 Count PanSink">0</h1>
                </a>
              </div>
            </div>
            <div className="col-md-12 row mt-3 UsageTable align-content-start">
              <div className="col-12">
                <h2 className="col-12 mb-3 tableTitle">VIDEO CLIPS</h2>
              </div>
              <div className="col-12 p-0 m-0 row">
                <div className="col-4 p-0 m-0 bg-black">
                  <video height="180px" width="100%" loop autoPlay muted controls id="vid">
                    <source src="https://firebasestorage.googleapis.com/v0/b/vidan-99eac.appspot.com/o/streamVideo%2Ff2_Trim.mp4?alt=media&token=519458c2-5278-43dd-8e14-4e00e39aaf98" type="video/mp4" />
                  </video>
                </div>
                <div className="col-4 p-0 m-0 bg-black">
                  <video height="180px" width="100%" loop autoPlay muted controls id="vid">
                    <source src="https://firebasestorage.googleapis.com/v0/b/vidan-99eac.appspot.com/o/streamVideo%2Fsample3_Trim.mp4?alt=media&token=5ea9b09e-91a3-4109-8166-8e3769a324e6" type="video/mp4" />
                  </video>
                </div>
                <div className="col-4 p-0 m-0 bg-black">
                  <video height="180px" width="100%" loop autoPlay muted controls id="vid">
                    <source src="https://firebasestorage.googleapis.com/v0/b/vidan-99eac.appspot.com/o/streamVideo%2FCarDet_Trim.mp4?alt=media&token=46728bed-c5c6-4bfc-b3e3-eb8bf52a9d39" type="video/mp4" />
                  </video>
                </div>
                <div className="col-4 p-0 m-0 bg-black">
                  <video height="180px" width="100%" loop autoPlay muted controls id="vid">
                    <source src="https://firebasestorage.googleapis.com/v0/b/vidan-99eac.appspot.com/o/streamVideo%2Frobbery3GW2_Trim%20(2).mp4?alt=media&token=6a4a200b-a489-41a6-b405-c765fe9e404d" type="video/mp4" />
                  </video>
                </div>
                <div className="col-4 p-0 m-0 bg-black">
                  <video height="180px" width="100%" loop autoPlay muted controls id="vid">
                    <source src="https://firebasestorage.googleapis.com/v0/b/vidan-99eac.appspot.com/o/streamVideo%2F20211203_171545%2000_00_21-00_00_32.mp4?alt=media&token=69892d1c-c327-4526-a12d-e36c56abf340" type="video/mp4" />
                  </video>
                </div>
                <div className="col-4 p-0 m-0 bg-black">
                  <video height="180px" width="100%" loop autoPlay muted controls id="vid">

                    <source src="https://firebasestorage.googleapis.com/v0/b/vidan-99eac.appspot.com/o/streamVideo%2Fintruder.mp4?alt=media&token=86a1c698-ec40-4baa-9f95-5b06649de154" type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 AlertBos AlertBtn">
            <p className="text-center">
              <img src="vidan-logo-01.png" style={{ width: '160px', height: '40px' }} />
            </p>
            <div className="col-12">
              <h2 className="col-12 mb-3 tableTitle">ALERTS</h2>
            </div>
            <div className="col-12 p-0 m-0 AlertScroll">
              {this.state.alerts.map(alert => (
                <div className="col-12 text-center mt-2 alertRows">
                  <h6>{alert.KPI}</h6>
                  <div className="col-12 p-0 m-0 row justify-content-center">
                    <a onClick={() => this.dismiss(alert.id)} className="btn bg-danger text-white m-1">DISMISS</a>
                    <a onClick={() => this.view(alert)} className="btn bg-primary text-white m-1">VIEW</a>
                  </div>
                </div>))}

            </div>
          </div>
        </div>
      </div>
    );
  }
}