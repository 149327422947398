import React from 'react';
import './App.css';
import { auth } from './firebase'

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

export default class Vidan extends React.Component
{
     constructor(props){
      super(props);
      this.state={
        Email:'',
        Pasword:''
      }
     }
    componentDidMount() {
        let that = this
        auth.onAuthStateChanged(function(user){
            
        });
    }
     onFinish = async() => {
         console.log("here")
        //  console.log(values)s
        try
        {
           let response = await auth.signInWithEmailAndPassword(this.state.Email, this.state.Password)
            console.log(response)
        }
        catch(err) {
            alert(err)
        }
      };

    render(){
        console.log(auth.currentUser)
        return (
  <div style={{background:'white'}} >
        <nav className="navbar navbar-expand-lg navbar-light ">
	        <a className="navbar-brand" href="#">
          <img src={require("./assets/logo1.png")} /></a>
	      </nav>
	<div  className="container login">
		<div className="container">
    <div class="row col-md-12">
			<div className="col-md-7">
			<img className="w-100" src={require("./assets/loginimage.png")} />
			</div>
			 <div className="col-md-5 mt-5 mobilepadding extraspacing mb-5 ">
				<h2 className="text-center font-weight-bold gcolor">Member Login</h2>
				<div className="row inputfield emailicon mt-3">
					<input 
                    onChange={(ev)=>this.setState({Email:ev.target.value})}
                    className="w-75 " 
                    type="text" 
                    name="emailaddress" 
                    placeholder="Email Address" 
                    />
				</div>
				<div className="row inputfield mt-3">
					<input className="w-75" onChange={(ev)=>this.setState({Password:ev.target.value})} type="password" name="password" placeholder="Password" />
				</div>
				<div className="row mt-3">
					<button type="button" className="submitbutton w-75" onClick={this.onFinish}>Login</button>
				</div>
			</div>
			
		</div>
    </div>
	</div>
    </div>
       
           
        )
    }
}