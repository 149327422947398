import firebase from 'firebase';
const config = {
    apiKey: "AIzaSyDw30ITwuGFuGntLmSFjwrn6INjSd3gsLs",
    authDomain: "vidan-99eac.firebaseapp.com",
    databaseURL: "https://vidan-99eac.firebaseio.com",
    projectId: "vidan-99eac",
    storageBucket: "vidan-99eac.appspot.com",
    messagingSenderId: "218273030921",
    appId: "1:218273030921:web:58d7993d5524b56a0fe9d8",
    measurementId: "G-HX4LF8KR3G"
};
const firebaseApp = firebase.initializeApp(config);
let db = firebaseApp.firestore();
let auth = firebaseApp.auth()
let storage = firebaseApp.storage();
export  {
    db,
    storage,
    auth
 }