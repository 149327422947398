import React from 'react';
import './App.css';
import { Form, Input, Button, Checkbox, Card } from 'antd';
import 'antd/dist/antd.css';
import { auth } from './firebase'

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

export default class Vidan extends React.Component
{
   
    
    componentDidMount() {
        let that = this
        auth.onAuthStateChanged(function(user){
            
        });
    }
     onFinish = async(values) => {
        //  console.log(values)
        try
        {
           let response = await auth.signInWithEmailAndPassword(values.username, values.password)
            console.log(response)
        }
        catch(err) {
            alert(err)
        }
      };
    
    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };
    
    render(){
        console.log(auth.currentUser)
        return (
           
            <div className="loginBody">
         
            <div className="content">
             
                <Card>
  <Form
      {...layout}
      name="basic"
      onFinish={this.onFinish}
      onFinishFailed={this.onFinishFailed}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            type:'email',
            required:true,
            message: 'Enter Valid Email',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Login
        </Button>
      </Form.Item>
    </Form>
    </Card>
    </div>
            </div>
        )
    }
}